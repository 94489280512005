<script lang="ts" context="module">
  import debounce from "lodash.debounce";
  import { validateNewPassword } from "./passwordValidations";
  import { fade, fly } from "svelte/transition";
  import Field from "./Field.svelte";
  import PasswordRules from "./PasswordRules.svelte";
</script>

<script lang="ts">
  export let title: string;
  export let password: string = "";
  export let passwordValid: boolean;
  export let autoFocus: boolean = false;
  export let keepRulesVisible: boolean = false;

  let passwordStrength = validateNewPassword(password);

  export let passwordFieldIsFocused: boolean = false;
  let userLeftField: boolean = false;

  $: passwordFieldWasInFocused = passwordFieldWasInFocused || passwordFieldIsFocused;

  const debouncePasswordChange = debounce((evt: Event) => {
    password = (evt.target as HTMLInputElement).value;
    passwordStrength = validateNewPassword(password);
  }, 200);

  $: passwordValid = passwordStrength.isValid;
</script>

<Field
  showRequired={userLeftField && passwordStrength.length < 1}
  {title}
  type="password"
  {autoFocus}
  value={password}
  bind:isValid={passwordValid}
  bind:userLeftInput={userLeftField}
  bind:inputFieldIsFocused={passwordFieldIsFocused}
  on:input={debouncePasswordChange}
  placeholder="Enter your password" />

{#if userLeftField && !passwordStrength.isValid && passwordStrength.length > 0}
  <div in:fly={{ y: -10, opacity: 0 }} class="absolute flex flex-row items-center justify-start text-13 font-normal text-red-600">
    <span class="material-icons font-medium" style="font-size:13px; font-weight:600;">warning_amber</span>
    <span>Your password is invalid</span>
  </div>
{/if}

{#if passwordFieldIsFocused || (keepRulesVisible && passwordFieldWasInFocused)}
  <div class="mt-4 text-12" in:fade>
    <PasswordRules {passwordStrength} />
  </div>
{/if}
