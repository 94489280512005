<script lang="ts">
  import { fly } from "svelte/transition";

  export let showRequired: boolean = false;
  export let errorMessage: string | undefined = undefined;
  export let title: string | undefined = undefined;
</script>

<div class="relative w-full" {...$$restProps}>
  <label class="relative mb-1 inline-block w-full text-13 text-nxgray-600">
    {title}
    <slot />
    {#if errorMessage}
      <div in:fly={{ y: -10, opacity: 0 }} class="absolute flex flex-row items-center justify-start text-13 font-normal text-red-600">
        <span class="material-icons mr-0.5" style="font-size:13px; font-weight:600;">warning_amber</span>
        {errorMessage}
      </div>
    {/if}
  </label>
  {#if showRequired}
    <span in:fly={{ y: 10, opacity: 0 }} class="absolute right-0 top-0 text-13 font-normal text-red-600">Required</span>
  {/if}
</div>
