<script lang="ts" context="module">
  import { createEventDispatcher } from "svelte";
  import { postJson, ServerError } from "@ui/http";
  import { validateEmail, validateFirstName, validateLastName } from "./validations";
  import GoogleButton from "./GoogleButton.svelte";
  import type { SignupResult } from "./types";
  import { getAuthenticationContext } from "./context";
  import Recaptcha from "./Recaptcha.svelte";
  import AcceptTermsModal from "./AcceptTermsModal.svelte";
  import NewPasswordInput from "./NewPasswordInput.svelte";
  import { fade } from "svelte/transition";
  import Field from "./Field.svelte";
</script>

<script lang="ts">
  export let errorCode: string | undefined = undefined;
  export let email: string = "";
  export let showReCaptcha: boolean;
  export let showTermsAndContinueWithGoogle = false;

  const dispatcher = createEventDispatcher();
  const context = getAuthenticationContext();

  let submitting: boolean = false;
  let recaptchaToken: string | undefined;
  let recaptchaComponent: Recaptcha;
  let showAcceptTermsModal = showTermsAndContinueWithGoogle;
  let createNewAccountUsing: "email" | "google";
  let password: string = "";
  let passwordValid: boolean = false;
  let googleButtonElement: GoogleButton;

  let passwordFieldIsFocused: boolean = false;

  let userLeftEmailInput: boolean = false;
  let userLeftLastNameInput: boolean = false;
  let userLeftFirstNameInput: boolean = false;

  $: isOrgInvite = ($context.inviteToken || "").length > 0 && !$context.isMagicInvite;
  $: [emailValid, emailValidationMessage] = isOrgInvite ? [true, undefined] : validateEmail(email, "signup");
  $: [fnameValid, fnameValidationMessage] = validateFirstName($context.firstName);
  $: [lnameValid, lnameValidationMessage] = validateLastName($context.lastName);
  $: canSubmit = emailValid && passwordValid && !submitting && fnameValid && lnameValid && (showReCaptcha ? recaptchaToken : true);

  async function submitClicked() {
    if (!canSubmit) {
      return;
    }
    createNewAccountUsing = "email";
    showAcceptTermsModal = true;
  }

  async function clickedContinueWithGoogle() {
    createNewAccountUsing = "google";
    showAcceptTermsModal = true;
  }

  async function doSignup() {
    if (createNewAccountUsing === "google" || showTermsAndContinueWithGoogle) {
      googleButtonElement.continueWithGoogle();
      return;
    }

    submitting = false;
    resetError();
    try {
      submitting = true;
      const result = await postJson<SignupResult>("/app/auth/do_signup_json", {
        email,
        password,
        "first_name": $context.firstName,
        "last_name": $context.lastName,
        "g-recaptcha-response": recaptchaToken,
        "invite_token": $context.inviteToken
      });

      dispatcher("signup", result);
    } catch (e) {
      if (showReCaptcha && recaptchaComponent) {
        recaptchaComponent.reset();
      }
      const ex: ServerError = e as ServerError;

      // errorCode is binded from the outer component and is handled there
      errorCode = ex.code || "fake_general_error_so_we_show_something";

      if (Array.isArray(errorCode)) {
        // Take the first error raised in Users.validate() method.
        errorCode = errorCode[0][0];

        // Focus the invalid input field according to the error raised in Users.validate() method.
        window.requestAnimationFrame(() => {
          //@ts-ignore
          const element: HTMLElement = document.querySelector(`[name=${errorCode}]`);
          if (element) {
            //@ts-ignore
            if (element.select) {
              //@ts-ignore
              element.select();
            }
            element.focus();
          }
        });
      }
    } finally {
      submitting = false;
      showAcceptTermsModal = false;
    }
  }

  function resetError() {
    errorCode = undefined;
  }
</script>

<AcceptTermsModal bind:isOpen={showAcceptTermsModal} on:acceptedTerms={doSignup} />

{#if !$context.hideHeaderText}
  <h2 class="mb-6 text-18 font-bold text-nxgray-600">Create a Smore account</h2>
{/if}
<GoogleButton
  mode="signup"
  bind:this={googleButtonElement}
  requireTOSAcceptance={true}
  on:clickWhileTOSRequired={clickedContinueWithGoogle} />
<div class="my-6 flex items-center justify-center">
  <hr class="flex-grow" />
  <span class="bg-white px-2 text-13 text-nxgray-300">or</span>
  <hr class="flex-grow" />
</div>
<div class="flex flex-col">
  <div class="grid grid-cols-2 gap-2">
    <Field
      errorMessage={fnameValidationMessage}
      showRequired={userLeftFirstNameInput && $context.firstName.length < 1}
      title="First name"
      isValid={fnameValid}
      type="text"
      autoFocus={true}
      bind:value={$context.firstName}
      bind:userLeftInput={userLeftFirstNameInput}
      autocomplete="given-name"
      placeholder="First name" />

    <Field
      errorMessage={lnameValidationMessage}
      showRequired={userLeftLastNameInput && $context.lastName.length < 1}
      title="Last name"
      isValid={lnameValid}
      type="text"
      bind:value={$context.lastName}
      bind:userLeftInput={userLeftLastNameInput}
      autocomplete="family-name"
      placeholder="Last name" />
  </div>

  <div class="mt-4">
    <Field
      errorMessage={emailValidationMessage}
      showRequired={userLeftEmailInput && email.length < 1}
      title="Email address"
      isValid={emailValid}
      type="text"
      bind:value={email}
      bind:userLeftInput={userLeftEmailInput}
      autocomplete="email"
      readOnly={isOrgInvite}
      tabIndex={isOrgInvite ? -1 : undefined}
      placeholder="name@example.com" />
  </div>

  <div class="my-4">
    <NewPasswordInput title="Password" bind:password bind:passwordValid bind:passwordFieldIsFocused />
  </div>
  {#if showReCaptcha && (email || password)}
    <div in:fade class="mb-4" class:mt-2={!passwordFieldIsFocused}>
      <Recaptcha bind:token={recaptchaToken} bind:this={recaptchaComponent} />
    </div>
  {/if}
  <input
    type="submit"
    on:click|preventDefault|stopPropagation={submitClicked}
    disabled={!canSubmit}
    class="w-full select-none rounded bg-gray-200 py-3 text-center text-15 font-bold text-white hover:bg-gray-100 focus:bg-gray-100"
    class:cursor-not-allowed={!canSubmit}
    class:bg-orange-600={canSubmit}
    class:clickable={canSubmit}
    class:hover:bg-orange-500={canSubmit}
    class:focus:bg-orange-500={canSubmit}
    value="Create a Smore account" />
</div>
