<script lang="ts">
  import Input from "./Input.svelte";
  import Label from "./Label.svelte";

  export let showRequired: boolean = false;
  export let title: string | undefined = undefined;
  export let errorMessage: string | undefined = undefined;

  export let isValid: boolean = false;
  export let value: string = "";
  export let placeholder: string | undefined | null = undefined;
  export let autocomplete: string | undefined | null = undefined;
  export let userLeftInput: boolean = false;
  export let inputFieldIsFocused: boolean = false;
  export let readOnly: boolean | undefined | null = undefined;
  export let tabIndex: number | undefined | null = undefined;
  export let autoFocus: boolean = false;
  export let type: "password" | "text" = "text";
</script>

<Label {showRequired} {errorMessage} {title}>
  <Input
    {isValid}
    bind:value
    {placeholder}
    {autocomplete}
    bind:userLeftInput
    bind:inputFieldIsFocused
    {readOnly}
    {tabIndex}
    {autoFocus}
    {type}
    on:input />
</Label>
