<script lang="ts" context="module">
  import { postJson, ServerError } from "@ui/http";
  import { fade } from "svelte/transition";
  import NewPasswordInput from "./NewPasswordInput.svelte";
  import Label from "./Label.svelte";
  import Input from "./Input.svelte";
  import Field from "./Field.svelte";
  import { fly } from "svelte/transition";
</script>

<script lang="ts">
  export let token: string;

  let newPassword: string = "";
  let newConfirmationPassword: string = "";
  let showErrorMessage: boolean = false;
  let updateErrorMessage: string | null | undefined;
  let isSubmitting: boolean = false;
  let userLeftConfirmPassword: boolean = false;
  let isPasswordValid: boolean;

  const codeToMessage: { [code: string]: string } = {
    password_compromised: `Your password is too common or easy to guess.<br/>Please create a stronger password.`,
    password_breaks_policy: `Your password is too common or easy to guess.<br/>Please create a stronger password.`
  };

  $: canSubmitResetPasswordForm = isPasswordValid && isConfirmationPasswordValid;
  $: isConfirmationPasswordValid = newPassword == newConfirmationPassword && newPassword.length > 0;

  async function submitForm() {
    isSubmitting = true;
    const data = {
      new_password: newPassword,
      token: token
    };

    try {
      showErrorMessage = false;
      updateErrorMessage = null;
      const result = await postJson<{ redirectUrl: string }>("/app/auth/do_reset_password", data);
      document.location.href = result.redirectUrl;
    } catch (ex: any) {
      updateErrorMessage = codeToMessage[ex.code] || ServerError.GENERIC_ERROR;
      showErrorMessage = true;
      isSubmitting = false;
    }
  }
</script>

{#if showErrorMessage}
  <div transition:fade class="mb-4 rounded bg-red-500 px-4 py-2 font-bold text-white">
    {@html updateErrorMessage}
  </div>
{/if}
<h2 class="text-18 font-bold text-nxgray-600">Reset your password</h2>
<h3 class="mt-1 text-13 text-nxgray-600">Choose a new password below. And promise not to forget it this time!</h3>
<div class="mt-4 w-full">
  <input type="hidden" name="token" value={token} />
  <NewPasswordInput
    title="Enter a new password"
    bind:password={newPassword}
    bind:passwordValid={isPasswordValid}
    autoFocus={true}
    keepRulesVisible={true} />

  <div class="relative mb-4 mt-5">
    <Field
      showRequired={userLeftConfirmPassword && newConfirmationPassword.length < 1}
      title="Verify new password"
      type="password"
      bind:value={newConfirmationPassword}
      bind:isValid={isConfirmationPasswordValid}
      bind:userLeftInput={userLeftConfirmPassword} />

    {#if !isConfirmationPasswordValid && newConfirmationPassword.length}
      <div
        in:fly={{ y: -10, opacity: 0 }}
        class="absolute -bottom-[19px] flex flex-row items-center justify-start text-13 font-normal text-red-600">
        <span class="material-icons" style="font-size:13px; font-weight:600;">warning_amber</span>
        <span class="ml-0.5">Oops! Passwords must match</span>
      </div>
    {/if}
  </div>

  <button
    on:click|stopPropagation|preventDefault={submitForm}
    disabled={isSubmitting || !canSubmitResetPasswordForm}
    class="pointer-events-none mt-2 w-full select-none rounded bg-gray-200 py-3 text-center text-15 font-bold text-white hover:bg-gray-100 focus:bg-gray-100"
    class:pointer-events-auto={!isSubmitting && canSubmitResetPasswordForm}
    class:clickable={!isSubmitting && canSubmitResetPasswordForm}
    class:bg-orange-600={!isSubmitting && canSubmitResetPasswordForm}
    class:hover:bg-orange-500={!isSubmitting && canSubmitResetPasswordForm}
    class:focus:bg-orange-500={!isSubmitting && canSubmitResetPasswordForm}
    >{isSubmitting ? "Resetting Password" : "Reset Password"}</button>
</div>
