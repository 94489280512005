<script lang="ts" context="module">
  import debounce from "lodash.debounce";
  import { onMount } from "svelte";
</script>

<script lang="ts">
  export let isValid: boolean = false;
  export let value: string = "";
  export let placeholder: string | undefined | null = undefined;
  export let autocomplete: string | undefined | null = undefined;
  export let userLeftInput: boolean = false;
  export let inputFieldIsFocused: boolean = false;
  export let readOnly: boolean | undefined | null = undefined;
  export let tabIndex: number | undefined | null = undefined;
  export let autoFocus: boolean = false;
  export let type: "password" | "text" = "text";

  let inputElement: HTMLInputElement;
  let showPassword: boolean = false;

  onMount(() => {
    inputElement.type = type;
    if (autoFocus) {
      inputElement.focus();
    }
  });

  function toggleShowPassword() {
    showPassword = !showPassword;
    inputBlur.cancel();
    inputFieldIsFocused = true;
    if (showPassword) {
      inputElement.type = "text";
    } else {
      inputElement.type = "password";
    }
    inputElement.focus();
  }

  const inputBlur = debounce(() => {
    inputFieldIsFocused = false;
    userLeftInput = true;
  }, 30);

  function inputFocus() {
    inputBlur.cancel();
    inputFieldIsFocused = true;
    userLeftInput = false;
  }
</script>

<div class="relative w-full">
  <input
    class="w-full rounded border border-nxgray-500 px-3 py-2 text-14 outline outline-2"
    class:pr-10={type === "password"}
    class:bg-nxgray-50={readOnly}
    class:text-nxgray-300={readOnly}
    class:pointer-events-none={readOnly}
    class:text-nxgray-600={!readOnly}
    class:valid={!readOnly && isValid}
    class:custom={!readOnly && (inputFieldIsFocused || !value.length)}
    class:outline-transparent={!value.length || readOnly || !inputFieldIsFocused}
    class:outline-red-200={!readOnly && !isValid && inputFieldIsFocused}
    class:outline-green-300={!readOnly && isValid && inputFieldIsFocused}
    class:border-gray-300={!readOnly && !inputFieldIsFocused}
    class:border-nxgray-500={!readOnly && inputFieldIsFocused && !value.length}
    class:border-red-500={!readOnly && !isValid && inputFieldIsFocused && value.length}
    class:border-green-700={!readOnly && isValid && inputFieldIsFocused}
    bind:this={inputElement}
    on:blur={inputBlur}
    on:focus={inputFocus}
    bind:value
    on:input
    {autocomplete}
    {placeholder}
    readonly={readOnly}
    tabindex={tabIndex} />
  {#if type === "password"}
    <button
      class="clickable absolute right-4 top-1/4 h-4 w-4 text-nxgray-400 outline-2 outline-offset-2"
      on:click|stopPropagation|preventDefault={toggleShowPassword}
      on:blur={inputBlur}
      on:focus={inputFocus}>
      <span class="material-icons pointer-events-none text-18">
        {#if showPassword}
          visibility_off
        {:else}
          visibility
        {/if}
      </span>
    </button>
  {/if}
</div>

<style>
  input:not(.custom):focus,
  input:not(.custom):not([readonly]).valid:focus {
    border-color: theme("borderColor.nxgray.600");
  }
</style>
