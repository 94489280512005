<script lang="ts">
  import { onMount } from "svelte";
  export let loaded: boolean = false;
  export let token: string | undefined = undefined;

  const RECAPTCHA_KEY = document.head.querySelector("#smore_captcha_key")?.getAttribute("value");

  function verify(recaptchaValue: string) {
    token = recaptchaValue;
  }

  let el: HTMLDivElement;

  async function tryInitializingRecaptcha() {
    if (window.grecaptcha && window.__captchaReadyPromise) {
      if (loaded) {
        return;
      }
      await window.__captchaReadyPromise;
      loaded = true;
      window.grecaptcha.render(el, {
        sitekey: RECAPTCHA_KEY,
        callback: verify,
        size: "normal"
      });
    } else {
      setTimeout(tryInitializingRecaptcha, 50);
    }
  }

  export function reset() {
    if (window.grecaptcha) {
      token = undefined;
      window.grecaptcha.reset();
    }
  }

  onMount(tryInitializingRecaptcha);
</script>

<div bind:this={el} class="flex items-center justify-center px-2" />
